'use client';

import { useCallback, useEffect, useState } from 'react';
import Flex from '~/components/customComponents/flex/Flex';

type FormDeliveryDateProps = {
  actualDeliveryDate: Date;
  setValue: (key: string, value: any) => void;
};

const FormDeliveryDate = ({
  actualDeliveryDate,
  setValue,
}: FormDeliveryDateProps) => {
  const [date, setDate] = useState(
    actualDeliveryDate ? new Date(actualDeliveryDate) : new Date()
  );

  useEffect(() => {
    if (actualDeliveryDate) setDate(new Date(actualDeliveryDate));
  }, [actualDeliveryDate]);

  const handleDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedDate = new Date(event.target.value);
      setDate(selectedDate);
      setValue('actual_delivery_date', selectedDate.toISOString());
    },
    [setValue]
  );

  if (!date) return null;

  return (
    <Flex className="w-full justify-center">
      <input
        type="date"
        onChange={handleDateChange}
        value={date.toISOString().split('T')[0]}
        className="border border-gray-300 rounded-md p-2 w-64"
      />
    </Flex>
  );
};

export default FormDeliveryDate;
