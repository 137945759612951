'use client';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import Banner from '~/components/core/Banner/Banner';
import Counter from '~/components/core/Counter';
import { useI18n } from '~/locales/client';
import { twMerge } from 'tailwind-merge';
import { ProductImage } from '~/app/[locale]/home/_components/Products/ProductImage';
import Select from '~/components/core/Select/Select';
import Flex from '~/components/customComponents/flex/Flex';
import SimpleSkeleton from '~/components/customComponents/skeletons/SimpleSkeleton';
import Text from '~/components/customComponents/texts/Text';
import { ProductCategory, SelectOptions } from '~/utils/claimUtils';
import { AffectedArticleType, UnitEnum } from '~/types-and-enums/claimTypes';
import { useStepContext } from '../../context/step';
import useFetchProducts, { Product } from '../../hooks/useFetchProducts';
import { BannerTypeEnum } from '~/types-and-enums/bannerTypes';
import Card from '~/components/customComponents/cards/Card';
import Checkbox from '~/components/customComponents/checkbox/Checkbox'; // Import your custom Checkbox

const ProductRow = ({
  product,
  index,
  setValue,
  affectedArticles,
}: {
  product: Product;
  index: number;
  setValue: (key: string, value: any) => void;
  affectedArticles: AffectedArticleType[];
}) => {
  const t = useI18n();

  const [hasDamage, setHasDamage] = useState(
    affectedArticles[index]?.hasDamage
  );

  const [damageCount, setDamageCount] = useState(
    affectedArticles[index]?.damaged_products_quantity ?? 0
  );
  const [damageUnit, setDamageUnit] = useState<UnitEnum>(
    affectedArticles[index]?.damaged_products_quantity_unit ?? UnitEnum.pieces
  );

  const [originalCount, setOriginalCount] = useState(
    affectedArticles[index]?.original_packed_products_quantity ?? 0
  );
  const [originalUnit, setOriginalUnit] = useState<UnitEnum>(
    affectedArticles[index]?.original_packed_products_quantity_unit ??
      UnitEnum.pieces
  );

  const handleDamagedCounterChange = (newValue: number) => {
    setDamageCount(newValue);
    const newAffectedArticles = [...affectedArticles];
    const product = newAffectedArticles[index];
    newAffectedArticles[index] = {
      ...product,
      hasDamage: newValue > 0,
      damaged_products_quantity: newValue,
      damaged_products_quantity_unit: damageUnit,
    };

    setValue('affected_articles', newAffectedArticles);
  };
  const handleDamagedUnitChange = (newValue: UnitEnum) => {
    setDamageUnit(newValue);
    const newAffectedArticles = [...affectedArticles];
    const product = newAffectedArticles[index];
    newAffectedArticles[index] = {
      ...product,
      damaged_products_quantity_unit: newValue,
    };

    setValue('affected_articles', newAffectedArticles);
  };

  const handleOriginalCountChange = (newValue: number) => {
    setOriginalCount(newValue);
    const newAffectedArticles = [...affectedArticles];
    const product = newAffectedArticles[index];
    newAffectedArticles[index] = {
      ...product,
      original_packed_products_quantity: newValue,
    };

    setValue('affected_articles', newAffectedArticles);
  };

  const handleOriginalUnitChange = (newValue: UnitEnum) => {
    setOriginalUnit(newValue);
    const newAffectedArticles = [...affectedArticles];
    const product = newAffectedArticles[index];
    newAffectedArticles[index] = {
      ...product,
      original_packed_products_quantity_unit: newValue,
    };

    setValue('affected_articles', newAffectedArticles);
  };

  return (
    <>
      <Flex
        className={twMerge(
          'max-w-192 gap-2 mb-2 mx-auto',
          hasDamage ? 'border-gray-900' : 'border-gray-100',
          hasDamage ? 'rounded-xl' : 'rounded-0',
          hasDamage && 'border'
        )}
      >
        <Flex className="flex-col w-full">
          <Flex className="p-6">
            <Checkbox
              label={product.product_name} // Passing product name as label
              id={`checkbox-${product.id}`} // Dynamic ID based on product ID
              value={hasDamage ?? false} // Setting the value of the checkbox
              onClick={(newValue) => setHasDamage(newValue)} // Handling state change when checkbox is clicked
            />
            <Flex className="items-center pl-2 w-full">
              <ProductImage
                productCategory={product.category as ProductCategory}
                imageUrl={product.imageURL}
                className="mr-2"
              />
              <Text className="font-medium text-sm mr-2">
                {product.product_name}
              </Text>
            </Flex>
          </Flex>
          {hasDamage && (
            <Flex className="p-6 bg-gray-100 justify-between rounded-b-xl items-center w-full">
              <Flex className="flex-col gap-2">
                <Text className="font-medium text-sm">
                  {t('case.new-request.view-step3.damage-count')}
                </Text>
                <Flex className="gap-4 items-center">
                  <Counter
                    amount={1}
                    max={100000}
                    maxWidth={150}
                    value={damageCount}
                    onChange={(value) => {
                      handleDamagedCounterChange(value);
                    }}
                  />
                  <div className="w-36">
                    <Select
                      placeholder={t(SelectOptions[0].label)}
                      options={SelectOptions.map((option) => ({
                        ...option,
                        label: t(option.label),
                      }))}
                      onChange={(value) => {
                        handleDamagedUnitChange(value);
                      }}
                      value={damageUnit}
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex className="flex-col gap-2">
                <Text className="font-medium text-sm mr-auto">
                  {t('case.new-request.view-step3.original-package-count')}
                </Text>
                <Flex className="gap-4  items-center">
                  <Counter
                    amount={1}
                    max={100000}
                    maxWidth={150}
                    value={originalCount}
                    onChange={(value) => {
                      handleOriginalCountChange(value);
                    }}
                  />
                  <div className="w-36">
                    <Select
                      placeholder={t(SelectOptions[0].label)}
                      options={SelectOptions.map((option) => ({
                        ...option,
                        label: t(option.label),
                      }))}
                      value={originalUnit}
                      onChange={(value) => {
                        handleOriginalUnitChange(value);
                      }}
                    />
                  </div>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      {!hasDamage && <hr className="max-w-192 mx-auto" />}
    </>
  );
};

const FormProductDamageCount = ({
  fulfillmentId,
  affectedArticles,
  setValue,
}: {
  fulfillmentId: string;
  affectedArticles: AffectedArticleType[];
  setValue: (key: string, value: any) => void;
}) => {
  const { products, loading, error, setLoading } =
    useFetchProducts(fulfillmentId);
  const { changeStep } = useStepContext();
  const t = useI18n();

  useEffect(() => {
    if (isEmpty(affectedArticles)) {
      const newAffectedArticles = products.map((product) => {
        return {
          ...product,
          original_packed_products_quantity: 0,
          original_packed_products_quantity_unit: UnitEnum.pieces,
          damaged_products_quantity: 0,
          damaged_products_quantity_unit: UnitEnum.pieces,
        };
      });
      setValue('affected_articles', newAffectedArticles);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (loading) {
    return (
      <SimpleSkeleton className="h-40 w-full max-w-132 rounded-xl mx-auto" />
    );
  }

  if (error) {
    return (
      <div className="mt-10">
        <Banner
          hasBorder={false}
          bannerType={BannerTypeEnum.Error}
          text={t('case.new-request.view.loading-error')}
        />
      </div>
    );
  }

  if (!loading && products.length === 0) {
    changeStep(1);
  }

  return (
    <Card>
      {products.map((product, index) => (
        <ProductRow
          key={index}
          index={index}
          product={product}
          affectedArticles={affectedArticles}
          setValue={setValue}
        />
      ))}
    </Card>
  );
};

export default FormProductDamageCount;
