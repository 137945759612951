'use client';
import { useState } from 'react';
import { FormField } from '~/components/core/FormField';
import { useI18n } from '~/locales/client';

const FormOther = ({
  description,
  setValue,
}: {
  description?: string;
  setValue: (key: string, value: any) => void;
}) => {
  const [text, setText] = useState(description ?? '');
  const t = useI18n();

  return (
    <FormField
      name={''}
      onChange={(value) => {
        setText(value);
        setValue('other_reason_description', value);
      }}
      value={text}
      type="textarea"
      label={t('case.new-request.view.step3.description')}
      placeholder={t('case.new-request.view.step3.describe')}
      classNames="max-w-[28.5rem] mx-auto border border-gray-200 rounded-lg p-4"
    />
  );
};

export default FormOther;
