export enum GTM_EVENT_ENUM {
  AllPages = 'TRIGGER_ALL_PAGES_EXCEPT_EMPLOYEES',
  Page_Scroll = 'TRIGGER_PAGE_SCROLL',
  Download_Document = 'TRIGGER_DOCUMENT_DOWNLOAD_CLICKED',
  StoreSearch = 'TRIGGER_STORE_SEARCH',
  STORE_CATEGORY_PRODUCT_SELECTED = 'TRIGGER_STORE_CATEGORY_PRODUCT_SELECTED',
  Store_PDP_Request_Quote = 'TRIGGER_STORE_QUOTES_REQUEST_BUTTON_CLICKED',
  Select_Category = 'TRIGGER_STORE_CATEGORY_SELECTED',
  Select_Top_Product = 'TRIGGER_STORE_TOP_PRODUCT_SELECTED',
  Quotes_Clicked_Request_Button = 'TRIGGER_QUOTES_REQUEST_BUTTON_CLICKED',
  Quotes_Selected_Status = 'TRIGGER_QUOTES_STATUS_SELECTED',
  Quotes_Selected_Quote = 'TRIGGER_QUOTES_QUOTE_SELECTED',
  Quotes_Search = 'TRIGGER_QUOTES_SEARCH',
  Quotes_Details_Selected_Category = 'TRIGGER_QUOTES_DETAILS_CATEGORY_SELECTED',
  Quotes_Request_Cancel = 'TRIGGER_QUOTE_REQUEST_CANCEL',
  Quotes_Request_Continue = 'TRIGGER_QUOTE_REQUEST_CONTINUE',
  Quotes_Request_Submit = 'TRIGGER_QUOTE_REQUEST_SUBMIT',
  Order_Selected_Status = 'TRIGGER_ORDERS_STATUS_SELECTED',
  CLAIM_CREATE_CLAIM = 'TRIGGER_CLAIM_START',
  CLAIM_REPORT_CLAIM = 'TRIGGER_CLAIM_REPORT',
  CLAIM_SEND_MESSAGE = 'TRIGGER_CLAIM_SEND_MESSAGE',
  CLAIM_RESOLVE = 'TRIGGER_CLAIM_RESOLVE',
  OFFER_ACCEPTED = 'TRIGGER_OFFER_ACCEPTED',
  OFFER_REJECTED = 'TRIGGER_OFFER_REJECTED',
  DELIVERY_REPORT_A_CASE = 'TRIGGER_DELIVERY_REPORT_A_CASE',
  DELIVERY_VIEW_OFFER_CLICKED = 'TRIGGER_DELIVERY_VIEW_OFFER_CLICKED',
  DELIVERY_DOWNLOAD_DOCUMENT = 'TRIGGER_DELIVERY_DOWNLOAD_DOCUMENT',
  DELIVERY_TRACKING_INITIAL_LANDING = 'TRIGGER_DELIVERY_TRACKING_INITIAL_LANDING',
  CHECKOUT_EMAIL_CHANGED = 'TRIGGER_CHECKOUT_EMAIL_CHANGED',
  CHECKOUT_SELECTED_BNPL_PLAN = 'TRIGGER_CHECKOUT_SELECTED_BNPL_PLAN',
  CHECKOUT_SELECTED_STANDARD_PLAN = 'TRIGGER_CHECKOUT_SELECTED_STANDARD_PLAN',
  CHECKOUT_CONFIRM_PLACE_ORDER = 'TRIGGER_CHECKOUT_CONFIRM_PLACE_ORDER',
  CHECKOUT_USER_INITIAL_LANDING = 'TRIGGER_CHECKOUT_USER_INITIAL_LANDING',
  CHECKOUT_BNPL_ELIGIBILITY = 'TRIGGER_CHECKOUT_BNPL_ELIGIBILITY',
  CHECKOUT_USER_REQUEST_ORDER_SUMMARY = 'TRIGGER_CHECKOUT_USER_REQUEST_ORDER_SUMMARY',
  PDP_INITAL_USER_LANDING = 'TRIGGER_PDP_INITAL_USER_LANDING',
  PDP_PRICE_CHANGE_ERROR = 'TRIGGER_PDP_PRICE_CHANGE_ERROR',
  PDP_DETAILS_CHANGE = 'TRIGGER_PDP_DETAILS_CHANGE',
  PDP_ITEM_ADDED_TO_CART = 'TRIGGER_PDP_ITEM_ADDED_TO_CART',
  PDP_DOWNLOAD_PRODUCT_SPEC = 'TRIGGER_PDP_DOWNLOAD_PRODUCT_SPEC',
  CART_ITEM_ERRORS = 'TRIGGER_CART_ITEM_ERRORS',
  CART_ITEM_QUANTITY_CHANGED = 'TRIGGER_CART_ITEM_QUANTITY_CHANGED',
  CART_ITEM_DELIVERY_DATE_CHANGED = 'TRIGGER_CART_ITEM_DELIVERY_DATE_CHANGED',
  CART_ITEM_REMOVED = 'TRIGGER_CART_ITEM_REMOVED',
  CART_ON_USER_INITIAL_LANDING = 'TRIGGER_CART_ON_USER_INITIAL_LANDING',
  CART_USER_CLICKED_CHECKOUT = 'TRIGGER_CART_USER_CLICKED_CHECKOUT',
  ACCOUNT_EXECUTIVE_CALL_CART_PAGE = 'TRIGGER_ACCOUNT_EXECUTIVE_CALL_CART_PAGE',
  ACCOUNT_EXECUTIVE_EMAIL_CART_PAGE = 'TRIGGER_ACCOUNT_EXECUTIVE_EMAIL_CART_PAGE',
  ACCOUNT_EXECUTIVE_CALL_CHECKOUT_PAGE = 'TRIGGER_ACCOUNT_EXECUTIVE_CALL_CHECKOUT_PAGE',
  ACCOUNT_EXECUTIVE_EMAIL_CHECKOUT_PAGE = 'TRIGGER_ACCOUNT_EXECUTIVE_EMAIL_CHECKOUT_PAGE',
}

export type GTM_USER_TYPE = {
  user_id: string;
  user_name: string;
  user_email: string;
  user_locale: string;
  user_isEmployee: boolean;
  user_isNew: boolean;
};
