'use client';

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { SelectOption } from '~/components/core/Select/Select';
import { Product } from '../hooks/useFetchProducts';
import { ClaimDataType, ClaimTypeEnum } from '~/types-and-enums/claimTypes';
import { useSearchParams } from 'next/navigation';
import {
  QUERY_PARAM_CATEGORY,
  QUERY_PARAM_FULFILLMENT_EVENT_ID,
} from '~/constants/queryParams';
import { updateSearchParams } from '~/utils/clientUtils';

const initialFormData: ClaimDataType = {};

const FormDataContext = createContext<{
  formData: ClaimDataType;
  updateFormData: (data: Partial<ClaimDataType>) => void;
  invoices: SelectOption[];
  products: Product[];
  fulfillmentEvents: SelectOption[];
  setInvoices: (data: SelectOption[]) => void;
  setProducts: (data: Product[]) => void;
  setFulfillmentEvents: (data: SelectOption[]) => void;
  overrideFormData: (data: Partial<ClaimDataType>) => void;
}>({
  formData: initialFormData,
  updateFormData: () => {},
  invoices: [],
  products: [],
  fulfillmentEvents: [],
  setInvoices: () => {},
  setProducts: () => {},
  setFulfillmentEvents: () => {},
  overrideFormData: () => {},
});

export const FormDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [formData, setFormData] = useState<ClaimDataType>(initialFormData);
  const [invoices, setInvoices] = useState<SelectOption[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [fulfillmentEvents, setFulfillmentEvents] = useState<SelectOption[]>(
    []
  );
  const searchParams = useSearchParams();

  useEffect(() => {
    // Based on searchParams, update the formData state
    // If the searchParams has a fulfillment_event_id and category, update the formData state with it
    if (
      searchParams.get(QUERY_PARAM_FULFILLMENT_EVENT_ID) &&
      searchParams.get(QUERY_PARAM_CATEGORY)
    ) {
      setFormData((prev) => ({
        ...prev,
        fulfillment_event_id:
          searchParams.get(QUERY_PARAM_FULFILLMENT_EVENT_ID) || undefined,
        category:
          (searchParams.get(QUERY_PARAM_CATEGORY) as ClaimTypeEnum) ||
          undefined,
      }));
      updateSearchParams('');
    }
  }, [searchParams]);

  const updateFormData = (data: Partial<ClaimDataType>) => {
    setFormData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const overrideFormData = (data: Partial<ClaimDataType>) => {
    setFormData({
      ...data,
    });
  };

  return (
    <FormDataContext.Provider
      value={{
        formData,
        updateFormData,
        invoices,
        products,
        fulfillmentEvents,
        setInvoices,
        setProducts,
        setFulfillmentEvents,
        overrideFormData,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormDataContext = () => useContext(FormDataContext);
