'use client';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import Counter from '~/components/core/Counter';
import { useI18n } from '~/locales/client';
import { useStepContext } from '../../context/step';
import useFetchProducts, { Product } from '../../hooks/useFetchProducts';
import Select from '~/components/core/Select/Select';
import { AffectedArticleType, UnitEnum } from '~/types-and-enums/claimTypes';
import { ProductImage } from '~/app/[locale]/home/_components/Products/ProductImage';
import { ProductCategory, SelectOptions } from '~/utils/claimUtils';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import { twMerge } from 'tailwind-merge';
import SimpleSkeleton from '~/components/customComponents/skeletons/SimpleSkeleton';
import Card from '~/components/customComponents/cards/Card';
import Banner from '~/components/core/Banner/Banner';
import { BannerTypeEnum } from '~/types-and-enums/bannerTypes';

const ProductRow = ({
  product,
  isLast,
  index,
  setValue,
  affectedArticles,
}: {
  product: Product;
  isLast: boolean;
  index: number;
  setValue: (key: string, value: any) => void;
  affectedArticles: AffectedArticleType[];
}) => {
  const t = useI18n();
  const [count, setCount] = useState(
    affectedArticles[index]?.wrong_products_quantity ?? 0
  );
  const [selectedUnit, setSelectedUnit] = useState<UnitEnum>(UnitEnum.pieces);

  const handleCounterChange = (newValue: number) => {
    if (!affectedArticles) return;

    const newAffectedArticles = [...affectedArticles];
    const updatedProduct = {
      ...newAffectedArticles[index],
      wrong_products_quantity: newValue,
      wrong_products_quantity_unit: selectedUnit,
    };
    newAffectedArticles[index] = updatedProduct;

    setValue('affected_articles', newAffectedArticles);
  };

  const handleUnitChange = (unit: UnitEnum) => {
    setValue('affected_articles', (prevArticles: AffectedArticleType[]) => {
      const newArticles = [...prevArticles];
      newArticles[index] = {
        ...newArticles[index],
        wrong_products_quantity: count,
        wrong_products_quantity_unit: unit,
      };
      return newArticles;
    });
  };

  return (
    <Flex
      className={twMerge(
        'items-center py-4 px-6',
        isLast ? 'border-b-0' : 'border-b'
      )}
    >
      <ProductImage
        productCategory={product.category as ProductCategory}
        imageUrl={product.imageURL}
        className="mr-2"
      />
      <Flex className="w-full justify-between gap-2 md:gap-0 flex-col md:flex-row items-center">
        <Text className="font-medium text-sm mr-auto">
          {product.product_name}
        </Text>
        <Flex className="items-center gap-4">
          <Counter
            amount={1}
            max={100000}
            value={count}
            onChange={(value) => {
              setCount(value);
              handleCounterChange(value);
            }}
          />
          <div className="w-36">
            <Select
              options={SelectOptions.map((option) => ({
                ...option,
                label: t(option.label),
              }))}
              onChange={(value) => {
                setSelectedUnit(value as UnitEnum);
                handleUnitChange(value as UnitEnum);
              }}
              placeholder={t(SelectOptions[0].label)}
              value={selectedUnit}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

const FormProductCount = ({
  fulfillmentId,
  affectedArticles,
  setValue,
}: {
  fulfillmentId?: string;
  affectedArticles: AffectedArticleType[];
  setValue: (key: string, value: any) => void;
}) => {
  const t = useI18n();
  const { changeStep } = useStepContext();
  const { products, loading, setLoading, error } =
    useFetchProducts(fulfillmentId);
  useEffect(() => {
    if (isEmpty(affectedArticles)) {
      const newAffectedArticles = products.map((product) => {
        return {
          ...product,
          wrong_products_quantity: 0,
          wrong_products_quantity_unit: UnitEnum.pieces,
        };
      });
      setValue('affected_articles', newAffectedArticles);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (loading) {
    return (
      <SimpleSkeleton className="h-40 w-full max-w-132 rounded-xl mx-auto" />
    );
  }

  if (error) {
    <div className="mt-10">
      <Banner
        hasBorder={false}
        bannerType={BannerTypeEnum.Error}
        text={t('case.new-request.view.loading-error')}
      />
    </div>;
  }
  if (!loading && products.length === 0) {
    changeStep(1);
  }

  return (
    <Card className="max-w-192 mx-auto px-0 py-2 gap-2">
      {products.map((product, index) => (
        <ProductRow
          key={index}
          index={index}
          product={product}
          isLast={index === products.length - 1}
          affectedArticles={affectedArticles}
          setValue={setValue}
        />
      ))}
    </Card>
  );
};

export default FormProductCount;
