import { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';

import { useFormDataContext } from '../context/data';
import { formProducts, ProductCategory } from '~/utils/claimUtils';

export type Product = {
  id: string;
  name: string;
  product_name: string;
  imageURL: string;
  category: ProductCategory | null;
};

const useFetchProducts = (fulfillmentId?: string) => {
  const { products, setProducts } = useFormDataContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `/api/support/fulfillments/${fulfillmentId}/items`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(formProducts(data));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (fulfillmentId && isEmpty(products)) {
      fetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentId]);
  return { products, loading, setLoading, error };
};

export default useFetchProducts;
