'use client';

import { useCallback, useState } from 'react';
import Flex from '../flex/Flex';
import { Icon, IconName } from '~/components/core/Icon';
import { twMerge } from 'tailwind-merge';
import { colors, spacing } from '~/utils/tailwindUtils';
import { ColorModeEnum } from '~/utils/generalUtils';

type CheckboxProps = {
  label: string | React.ReactNode;
  id: string;
  onClick: (arg: boolean) => void;
  value: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};
/**
 * Checkbox component that is used to display a checkbox with a label and an icon to represent the checkbox state (checked/unchecked)
 * @param label - The label to be displayed next to the checkbox
 * @param id - The id of the checkbox
 * @param onClick - The function to call when the checkbox is clicked
 * @param value - The value of the checkbox
 * @param disabled - Whether the checkbox is disabled
 * @param isLoading - Whether the checkbox is in a loading state
 * @returns Checkbox component
 */

const Checkbox = ({
  label,
  id,
  onClick,
  value,
  disabled,
  isLoading,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(value);

  const clickHandler = useCallback(() => {
    setIsChecked(!isChecked);
    onClick(!isChecked);
  }, [isChecked, onClick]);

  return (
    <Flex className="items-center gap-4">
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={clickHandler}
        className="sr-only bg-red-500" // visually hide but keep accessible
        disabled={disabled}
      />
      <label htmlFor={id} className="relative ">
        <Icon
          name={
            isChecked ? IconName.CheckboxChecked : IconName.CheckboxUnChecked
          }
          className={twMerge(disabled ? 'opacity-20 ' : 'cursor-pointer')}
          color={isChecked ? colors.red[500] : colors.gray[500]}
          colorMode={isChecked ? ColorModeEnum.Fill : ColorModeEnum.Stroke}
        />
        {isLoading && (
          <Icon
            name={IconName.Loader}
            size={spacing[4]}
            className="animate-spin absolute top-1 left-1 opacity-50"
          />
        )}
      </label>
      <label
        htmlFor={id}
        className={twMerge(
          'font-medium cursor-pointer text-black',
          disabled && 'text-gray-400'
        )}
      >
        {label}
      </label>
    </Flex>
  );
};

export default Checkbox;
