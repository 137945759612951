import { ProductCategory } from '~/utils/claimUtils';

export type StepContextType = {
  currentStep: StepType;
  completedSteps: number[];
  numberOfSteps: number;
  steps: StepType[];
  changeStep: (id: number) => void;
  loadStep: (id: number) => void;
  jumpToStep: (id: number) => void;
  caseDetailsSubStep: 1 | 2;
  setCaseDetailsSubStep: (value: 1 | 2) => void;
};

/**
 * Contains the possible unit supported by Salesforce
 * - pieces = 'Piece'
 * - pallet = 'Pallet'
 * - container = 'Container'
 */
export enum UnitEnum {
  pieces = 'Piece',
  pallet = 'Pallet',
  container = 'Container',
}

export type FulfillmentEventType =
  | {
      name: string;
      to_city: string;
      to_country: string;
      to_date: string;
      to_postal_code: string;
      to_street: string;
    }
  | undefined;

export type InvoiceType = {
  number: string;
  payment_amount: number;
};

export type AffectedArticleType = {
  id: string;
  name: string;
  product_name: string;
  category: ProductCategory | null;
  wrong_products_quantity?: number;
  wrong_products_quantity_unit?: UnitEnum;
  isWrongProduct?: boolean;
  hasDamage?: boolean;
  damaged_products_quantity?: number;
  damaged_products_quantity_unit?: UnitEnum;
  original_packed_products_quantity?: number;
  original_packed_products_quantity_unit?: UnitEnum;
};

export type ClaimDataType = {
  category?: ClaimTypeEnum;
  reason?: ClaimReasonEnum;
  actual_delivery_date?: Date;
  invoice_id?: string;
  affected_articles?: AffectedArticleType[];
  fulfillment_event_id?: string;
  comment?: string;
  formData?: any;
  files?: FormData;
  other_reason_description?: string;
};

export type ClaimObjectType = {
  id: string;
  sf_id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
  details?: ClaimDetailsProp;
  category: ClaimTypeEnum;
  fulfillmentID?: string;
  products?: string[];
  documents?: AttachmentType[];
  comment?: string;
  reason: ClaimReasonEnum;
  fulfillment_event?: FulfillmentEventType;
  invoice?: InvoiceType;
  fulfillment_event_items?: ProductsListType;
  status: ClaimStatusEnum;
  invoice_id?: string;
};

export type PartialClaimReason =
  | ClaimReasonEnum.Other
  | ClaimReasonEnum.LateDelivery
  | ClaimReasonEnum.WrongQuantity
  | ClaimReasonEnum.WrongProduct
  | ClaimReasonEnum.PackagingDamage;

export type ReasonTextMapType = {
  [key in PartialClaimReason]: {
    title: string;
    description: string;
  };
};

export type AttachmentType = {
  title: string;
  link: string;
  comment_id: string;
};

export type ProductsListType = {
  product_name: string;
  category: ProductCategory | null;
  imageURL: string;
}[];

export type ClaimDetailsProp = {
  sf_id: string;
  fulfillmentID?: string;
  fulfillment_event?: FulfillmentEventType;
  invoice?: InvoiceType;
  documents?: AttachmentType[];
  comment?: string;
  other_reason_description?: string;
  reason: ClaimReasonEnum;
  fulfillment_event_items?: ProductsListType;
};

/**
 * Contains the possible ClaimTypes as defined in Salesforce
 * - Billing = 'Billing' // everything related to billing
 * - Quality = 'Quality' // Quality issues with a SINGLE Product
 * - Logistics = 'Logistics' // Issues happened in Logistic context, can affect multiple products
 */
export enum ClaimTypeEnum {
  Billing = 'Billing',
  Quality = 'Product',
  Logistics = 'Logistics',
}

/**
 * Contains the possible ClaimStatuses as defined in Salesforce
 * - Created = 'Not started'
 * - InProcess = 'In progress'
 * - PendingReview = 'Resolution accepted by both sides'
 * - Completed = 'Completed'
 * - Unresolvable = "Can't resolve"
 * - Closed = 'Closed'
 */
export enum ClaimStatusEnum {
  Created = 'Not started',
  InProcess = 'In progress',
  PendingReview = 'Resolution accepted by both sides',
  Completed = 'Completed',
  Unresolvable = "Can't resolve",
  Closed = 'Closed',
}

export type OverviewClaimType = {
  status: ClaimStatusEnum;
  claimId: string;
  name: string;
  sf_id: string;
  category: ClaimTypeEnum;
  created_at: Date;
  updated_at: Date;
  has_unread_comments: boolean;
};

/**
 * Contains the possible claim reasons as defined in Salesforce
 * - LateDelivery = 'Late delivery'
 * - WrongQuantity = 'Wrong quantity'
 * - PackagingDamage = 'External damage'
 * - Performance = 'Product performance'
 * - SerialNumber = 'Serial number not found in system'
 * - Warranty = 'Warranty is too short'
 * - Software = 'Software is outdated'
 * - ProductionDate = 'Production date too old'
 * - ProductQuantity = 'Wrong product quantity'
 * - BillingInformation = 'Wrong billing information'
 * - PaymentAmount = 'Wrong payment amount'
 * - PaidAlready = 'Invoice was paid already'
 * - MaterialDefect = 'Material defect, scratches on the surface'
 * - WrongProduct = 'Wrong goods'
 * - WrongDeliveryAddress = 'Wrong delivery address'
 * - Other = 'Other'
 */
export enum ClaimReasonEnum {
  LateDelivery = 'Late delivery',
  WrongQuantity = 'Wrong quantity',
  PackagingDamage = 'External damage',
  Performance = 'Product performance',
  SerialNumber = 'Serial number not found in system',
  Warranty = 'Warranty is too short',
  Software = 'Software is outdated',
  ProductionDate = 'Production date too old',
  ProductQuantity = 'Wrong product quantity',
  BillingInformation = 'Wrong billing information',
  PaymentAmount = 'Wrong payment amount',
  PaidAlready = 'Invoice was paid already',
  MaterialDefect = 'Material defect, scratches on the surface',
  WrongProduct = 'Wrong goods',
  WrongDeliveryAddress = 'Wrong delivery address',
  Other = 'Other',
}

export type StepType = {
  id: number;
  label: string;
  complete: boolean;
};

export type ClaimCommentType = {
  contact: { first_name: string; last_name: string };
  created_at: Date;
  companyName: string;
  sf_id: string;
  is_read: boolean;
  body: string;
  document_keys: any[];
  claim_id: string;
  claim2_id: string;
  contact_id: string;
  documents: any[];
};
