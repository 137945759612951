'use client';

import { useSearchParams } from 'next/navigation';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { QUERY_PARAM_CATEGORY } from '~/constants/queryParams';
import { StepContextType, StepType } from '~/types-and-enums/claimTypes';

// Context type

// Create the context
const StepContext = createContext<StepContextType | undefined>(undefined);

// Custom hook to access the context
const useStepContext = (): StepContextType => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error('useStepContext must be used within a StepProvider');
  }
  return context;
};

const StepProvider = ({
  initialSteps,
  children,
}: { initialSteps: StepType[] } & PropsWithChildren) => {
  const [steps, setSteps] = useState(initialSteps);
  const [currentStep, setCurrentStep] = useState<StepType>(initialSteps[0]);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [caseDetailsSubStep, setCaseDetailsSubStep] = useState<1 | 2>(1);
  const searchParams = useSearchParams();

  useEffect(() => {
    // If the searchParams has a fulfillment_event_id and category, update the following
    // - currentStep to steps[2]
    // - completedSteps to [1, 2]
    // - caseDetailsSubStep to 1
    if (searchParams.get(QUERY_PARAM_CATEGORY)) {
      setCurrentStep(steps[2]);
      setCompletedSteps([1, 2]);
      setCaseDetailsSubStep(1);
    }
  }, [searchParams, steps]);

  useEffect(() => {
    const stepz = steps.map((memoryStep) => {
      return memoryStep.id === currentStep.id ? currentStep : memoryStep;
    });

    setSteps(stepz);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  // to refresh on language change
  useEffect(() => {
    setSteps(initialSteps);
  }, [initialSteps]);

  const loadStep = (id: number) => {
    const step = steps.find((s) => s.id === id)!;
    setCurrentStep(step);
  };

  const changeStep = (nextStep: number) => {
    const step = steps.find((s) => s.id === currentStep.id + nextStep)!;
    setCompletedSteps((completedSteps) => [...completedSteps, currentStep.id]);
    setCurrentStep(step);
  };

  const jumpToStep = (selectedStepNumber: number) => {
    const selectedStep = steps.find((s) => s.id === selectedStepNumber)!;
    setCurrentStep(selectedStep);
  };

  return (
    <StepContext.Provider
      value={{
        currentStep,
        loadStep,
        changeStep,
        completedSteps,
        numberOfSteps: steps.length,
        steps,
        jumpToStep,
        caseDetailsSubStep,
        setCaseDetailsSubStep,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export { StepProvider, useStepContext };
