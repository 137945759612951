'use client';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ProductImage } from '~/app/[locale]/home/_components/Products/ProductImage';
import Flex from '~/components/customComponents/flex/Flex';
import SimpleSkeleton from '~/components/customComponents/skeletons/SimpleSkeleton';
import Text from '~/components/customComponents/texts/Text';
import { useI18n } from '~/locales/client';
import { AffectedArticleType } from '~/types-and-enums/claimTypes';
import { ProductCategory } from '~/utils/claimUtils';
import useFetchProducts, { Product } from '../../hooks/useFetchProducts';
import Card from '~/components/customComponents/cards/Card';
import Checkbox from '~/components/customComponents/checkbox/Checkbox'; // Import your custom Checkbox

const ProductRow = ({
  product,
  isLast,
  index,
  affectedArticles,
  setValue,
}: {
  product: Product;
  isLast: boolean;
  index: number;
  setValue: (key: string, value: any) => void;
  affectedArticles: AffectedArticleType[];
}) => {
  const [isWrongProduct, setIsWrongProduct] = useState(
    (affectedArticles[index]?.wrong_products_quantity ?? 0) > 0
  );

  useEffect(() => {
    const newAffectedArticles = [...affectedArticles];

    newAffectedArticles[index] = {
      ...product,
      wrong_products_quantity: isWrongProduct ? 1 : 0,
    };
    setValue('affected_articles', newAffectedArticles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWrongProduct]);

  return (
    <Flex
      className={twMerge(
        'justify-between h-20 max-w-[48.5rem] px-6 py-4 items-center border-gray-100',
        isLast ? 'border-b-0' : 'border-b'
      )}
    >
      <Checkbox
        label={product.product_name} // Passing the product name as the label
        id={`checkbox-${product.id}`} // Using a dynamic id based on the product id
        value={isWrongProduct}
        onClick={(newValue) => setIsWrongProduct(newValue)} // Update the state when checkbox is clicked
      />
      <Flex className="items-center pl-2 w-full">
        <ProductImage
          productCategory={product.category as ProductCategory}
          imageUrl={product.imageURL}
          className="mr-2"
        />
        <Text className="text-sm mr-auto font-normal">
          {product.product_name}
        </Text>
      </Flex>
    </Flex>
  );
};

const FormProductWrong = ({
  fulfillmentId,
  affectedArticles,
  setValue,
}: {
  fulfillmentId: string;
  affectedArticles: AffectedArticleType[];
  setValue: (key: string, value: any) => void;
}) => {
  const t = useI18n();
  const { products, loading, setLoading } = useFetchProducts(fulfillmentId);

  useEffect(() => {
    if (isEmpty(affectedArticles)) {
      const newAffectedArticles = products.map((product) => ({
        ...product,
        wrong_products_quantity: 0,
      }));

      setValue('affected_articles', newAffectedArticles);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (loading) {
    return (
      <SimpleSkeleton className="h-40 w-full max-w-132 mx-auto rounded-xl" />
    );
  }

  const wrongArticles = affectedArticles.filter(
    (article) => article?.wrong_products_quantity ?? 0 > 0
  );
  return (
    <>
      <Card className="max-w-192 overflow-y-auto max-h-[calc(100vh_-_30rem)]  mx-auto px-0 py-2 gap-2">
        {products.map((product, index) => (
          <ProductRow
            key={index}
            index={index}
            product={product}
            isLast={index === products.length - 1}
            affectedArticles={affectedArticles}
            setValue={setValue}
          />
        ))}
      </Card>
      {wrongArticles.length > 0 && (
        <Text className="text-right font-medium text-gray-800 max-w-192 mx-auto mt-10">
          {wrongArticles.length} {/* @ts-ignore */}
          {t('case.new-request.view.item-selection-count')}
        </Text>
      )}
    </>
  );
};

export default FormProductWrong;
