import { twMerge } from 'tailwind-merge';

type SimpleSkeletonProps = {
  className?: string;
};

const SimpleSkeleton = ({ className }: SimpleSkeletonProps) => {
  return (
    <div
      role="status"
      className={twMerge(
        'w-full animate-pulse rounded h-4 bg-gray-200',
        className
      )}
    />
  );
};

export default SimpleSkeleton;
