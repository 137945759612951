import { format } from 'date-fns';
import isEmpty from 'lodash.isempty';
import FormDeliveryDate from '~/app/[locale]/home/support/new-request/_components/DetailFormComponents/FormDeliveryDate';
import FormOther from '~/app/[locale]/home/support/new-request/_components/DetailFormComponents/FormOther';
import FormProductCount from '~/app/[locale]/home/support/new-request/_components/DetailFormComponents/FormProductCount';
import FormProductDamageCount from '~/app/[locale]/home/support/new-request/_components/DetailFormComponents/FormProductDamageCount';
import FormProductWrong from '~/app/[locale]/home/support/new-request/_components/DetailFormComponents/FormProductWrong';
import { IconName } from '~/components/core/Icon';
import { DD_MM_YYYY } from '~/constants';
import { LocaleEnum } from '~/locales/resources';
import {
  AffectedArticleType,
  ClaimCommentType,
  ClaimDataType,
  ClaimObjectType,
  ClaimReasonEnum,
  ClaimStatusEnum,
  ClaimTypeEnum,
  ReasonTextMapType,
  UnitEnum,
} from '~/types-and-enums/claimTypes';
import { formatDate } from './dateUtils';
import { GTM_EVENT_ENUM } from './gtmUtils';
import { Product } from '~/app/[locale]/home/support/new-request/hooks/useFetchProducts';
import { colors } from './tailwindUtils';

export const reasonComponentMap = {
  [ClaimReasonEnum.Other]: FormOther,
  [ClaimReasonEnum.LateDelivery]: FormDeliveryDate,
  [ClaimReasonEnum.WrongQuantity]: FormProductCount,
  [ClaimReasonEnum.WrongProduct]: FormProductWrong,
  [ClaimReasonEnum.PackagingDamage]: FormProductDamageCount,
};

export enum ProductCategory {
  Inverters = 'PV Inverters',
  Modules = 'PV Modules',
  Batteries = 'PV Batteries',
  Other = 'Other',
}

type ReasonObject = {
  labelKey: string;
  icon: string;
};

export const reasons: { [key in ClaimReasonEnum]: ReasonObject } = {
  // LOGISTICS
  [ClaimReasonEnum.LateDelivery]: {
    labelKey: 'reason.late-delivery',
    icon: IconName.LateDelivery,
  },
  [ClaimReasonEnum.WrongQuantity]: {
    labelKey: 'reason.wrong-quantity',
    icon: IconName.WrongQuantity,
  },
  [ClaimReasonEnum.WrongProduct]: {
    labelKey: 'reason.wrong-product-delivered',
    icon: IconName.WrongProducts,
  },
  [ClaimReasonEnum.PackagingDamage]: {
    labelKey: 'reason.packaging-damage',
    icon: IconName.PackagingDamage,
  },
  [ClaimReasonEnum.WrongDeliveryAddress]: {
    labelKey: 'wrong-delivery-address',
    icon: IconName.PackagingDamage,
  },
  // PRODUCT
  [ClaimReasonEnum.Performance]: {
    labelKey: 'reason.performance',
    icon: IconName.Performance,
  },
  [ClaimReasonEnum.SerialNumber]: {
    labelKey: 'reason.serial-number',
    icon: IconName.SerialNumber,
  },
  [ClaimReasonEnum.Warranty]: {
    labelKey: 'reason.warranty',
    icon: IconName.Warranty,
  },
  [ClaimReasonEnum.Software]: {
    labelKey: 'reason.software',
    icon: IconName.ReasonSoftware,
  },
  [ClaimReasonEnum.ProductionDate]: {
    labelKey: 'reason.production-date',
    icon: IconName.ProductionDate,
  },
  [ClaimReasonEnum.MaterialDefect]: {
    labelKey: 'reason.material-defect',
    icon: IconName.MaterialDefect,
  },
  // BILLING

  [ClaimReasonEnum.ProductQuantity]: {
    labelKey: 'reason.wrong-product-quantity',
    icon: IconName.WrongQuantity,
  },
  [ClaimReasonEnum.BillingInformation]: {
    labelKey: 'reason.wrong-billing-information',
    icon: IconName.BillingInformation,
  },
  [ClaimReasonEnum.PaymentAmount]: {
    labelKey: 'reason.wrong-payment-amount',
    icon: IconName.PaymentAmount,
  },
  [ClaimReasonEnum.PaidAlready]: {
    labelKey: 'reason.invoice-paid-already',
    icon: IconName.PaidAlready,
  },
  [ClaimReasonEnum.Other]: {
    labelKey: 'reason.other',
    icon: IconName.OtherReason,
  },
};

export const getAllowedReasons = (
  claimType: ClaimTypeEnum,
  productCategoy?: ProductCategory | null
) => {
  const billingReasons = [
    ClaimReasonEnum.WrongProduct,
    ClaimReasonEnum.BillingInformation,
    ClaimReasonEnum.PaymentAmount,
    ClaimReasonEnum.PaidAlready,
    ClaimReasonEnum.Other,
  ];

  const logisticReasons = [
    ClaimReasonEnum.LateDelivery,
    ClaimReasonEnum.WrongQuantity,
    ClaimReasonEnum.WrongProduct,
    ClaimReasonEnum.PackagingDamage,
    // ClaimReasonEnum.WrongDeliveryAddress, // TODO: CHECK WITH TEAM
    ClaimReasonEnum.Other,
  ];

  const qualityReasons =
    productCategoy === ProductCategory.Inverters
      ? [
          ClaimReasonEnum.Performance,
          ClaimReasonEnum.SerialNumber,
          ClaimReasonEnum.Warranty,
          ClaimReasonEnum.Software,
          ClaimReasonEnum.ProductionDate,
          ClaimReasonEnum.Other,
        ]
      : [
          ClaimReasonEnum.Performance,
          ClaimReasonEnum.SerialNumber,
          ClaimReasonEnum.MaterialDefect,
          ClaimReasonEnum.Other,
        ];

  const reasonsMap = {
    [ClaimTypeEnum.Billing]: billingReasons,
    [ClaimTypeEnum.Logistics]: logisticReasons,
    [ClaimTypeEnum.Quality]: qualityReasons,
  };

  return reasonsMap[claimType];
};

export const SelectOptions = [
  {
    label: 'page.store.view.units.piece',
    value: UnitEnum.pieces,
    id: UnitEnum.pieces,
  },
  {
    label: 'page.store.view.units.pallet',
    value: UnitEnum.pallet,
    id: UnitEnum.pallet,
  },
  {
    label: 'page.store.view.units.container',
    value: UnitEnum.container,
    id: UnitEnum.container,
  },
];

export const statusMapping = {
  [ClaimStatusEnum.Created]: {
    bg: colors.gray[100],
    color: colors.gray[900],
    labelKey: 'page.claimManagement.view.status.created',
  },
  [ClaimStatusEnum.InProcess]: {
    bg: colors.orange[50],
    color: colors.yellow[800],
    labelKey: 'page.claimManagement.view.status.process',
  },
  [ClaimStatusEnum.PendingReview]: {
    bg: colors.red[50],
    color: colors.red[500],
    labelKey: 'page.claimManagement.view.status.pending-review',
  },
  [ClaimStatusEnum.Completed]: {
    bg: colors.green[50],
    color: colors.green[800],
    labelKey: 'page.claimManagement.view.status.resolved',
  },
  [ClaimStatusEnum.Closed]: {
    bg: colors.green[50],
    color: colors.green[800],
    labelKey: 'page.claimManagement.view.status.resolved',
  },
  [ClaimStatusEnum.Unresolvable]: {
    bg: colors.green[50],
    color: colors.green[800],
    labelKey: 'page.claimManagement.view.status.resolved',
  },
};

export const createGTMData = (claimData: ClaimDataType) => {
  const today = new Date();
  const formattedDate = format(today, DD_MM_YYYY);
  return {
    createdDate: formattedDate,
    claimCategory: claimData.category,
    invoiceID: claimData.invoice_id ?? null,
    fulfillmentID: claimData.fulfillment_event_id ?? null,
    reason: claimData.reason,
    reasonDescription: claimData.other_reason_description ?? null,
    actualDeliveryDate: claimData.actual_delivery_date ?? null,
    affectedProducts: claimData.affected_articles ?? null,
    comment: claimData.comment,
    attachedFile: !!claimData.files,
  };
};

export const reasonTextMap: ReasonTextMapType = {
  [ClaimReasonEnum.Other]: {
    title: 'case.new-request.view.step3.describe',
    description: 'case.new-request.view.step3.other.description',
  },
  [ClaimReasonEnum.LateDelivery]: {
    title: 'case.new-request.view.step3.late-delivery.title',
    description: 'case.new-request.view.step3.late-delivery.description',
  },
  [ClaimReasonEnum.WrongQuantity]: {
    title: 'case.new-request.view.step3.wrong-quantity.title',
    description: 'case.new-request.view.step3.wrong-quantity.description',
  },
  [ClaimReasonEnum.WrongProduct]: {
    title: 'case.new-request.view.step3.wrong-products.title',
    description: 'case.new-request.view.step3.wrong-products.description',
  },
  [ClaimReasonEnum.PackagingDamage]: {
    title: 'case.new-request.view.step3.packaging-damage.title',
    description: 'case.new-request.view.step3.packaging-damage.description',
  },
};

export const sanitizeData = (data: ClaimDataType) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (!isEmpty(value)) {
      if (key === 'affected_articles') {
        acc[key as keyof ClaimDataType] =
          data.category === ClaimTypeEnum.Quality
            ? value
            : (value as AffectedArticleType[]).filter(
                (product: AffectedArticleType) =>
                  (product.wrong_products_quantity &&
                    product.wrong_products_quantity > 0) ||
                  (product.damaged_products_quantity &&
                    product.damaged_products_quantity > 0)
              );
      } else {
        acc[key as keyof ClaimDataType] = value;
      }
    }
    return acc;
  }, {} as ClaimDataType);
};

export const formProducts = (oldProducts: any) => {
  if (!oldProducts) return [];
  const newProducts = oldProducts.map((product: any) => {
    return {
      id: product.sf_id,
      name: product.name,
      product_name: product.product_name,
      imageURL: product.product_image_url,
      category: product.product_category,
    };
  });
  return newProducts;
};

/**
 * Determines whether a description is required for the given claim type and reason.
 * If the claim type is Billing and the reason is Other, the description is required.
 * If the claim type is Quality and the reason is Other, the description is required.
 * If the claim type is Logistics and any reason is selected, the description is required.
 *
 *If it returns true we move into the substep where user can add details for selected reason
 *
 * @param {ClaimTypeEnum} params.claimType - The type of claim.
 * @param {ClaimReasonEnum} params.watchedReason - The reason for the claim.
 * @return {boolean} Returns true if a description is required, false otherwise.
 */
export const getHasDescriptionToReason = ({
  claimType,
  watchedReason,
}: {
  claimType: ClaimTypeEnum;
  watchedReason: ClaimReasonEnum;
}): boolean => {
  const isBillingWithOtherReason =
    claimType === ClaimTypeEnum.Billing &&
    watchedReason === ClaimReasonEnum.Other;
  const isLogisticsWithAnyReason =
    claimType === ClaimTypeEnum.Logistics && !!watchedReason;
  const isQualityWithAnyReason =
    ClaimTypeEnum.Quality && watchedReason === ClaimReasonEnum.Other;

  return (
    isBillingWithOtherReason ||
    isLogisticsWithAnyReason ||
    isQualityWithAnyReason
  );
};
export const createGTMDataForResolveAction = (
  claim: ClaimObjectType,
  commentCount: number,
  locale: LocaleEnum
) => {
  const today = new Date();
  const formattedDate = formatDate(today, locale);
  return {
    resolvedDateTime: formattedDate,
    claimID: claim.sf_id,
    createdDateTime: formatDate(claim.created_at, locale),
    numberOfComment: commentCount,
    category: claim.category,
    invoiceID: claim.invoice_id ?? null,
    fulfillmentID: claim.fulfillment_event ?? null,
    reason: claim.reason,
  };
};

export type ClaimDocumentType = {
  id: string;
  name: string;
};

export type ClaimDocumentsList = ClaimDocumentType[] | [];

export interface FormInputsComment {
  message: string;
  attachments: ClaimDocumentsList;
}

export const createGTMDataForCommentSubmit = (
  data: FormInputsComment,
  locale: LocaleEnum,
  lastComment: ClaimCommentType,
  hasFiles: boolean
) => {
  const today = new Date();
  const formattedDate = formatDate(today, locale as LocaleEnum);
  const gtmData = {
    previousComment: lastComment,
    message: data.message,
    createdDate: formattedDate,
    hasFiles,
  };
  return {
    event: GTM_EVENT_ENUM.CLAIM_SEND_MESSAGE,
    ...gtmData,
  };
};

export const mockClaim = {
  id: '1',
  sf_id: '000000001',
  name: 'Test Claim',
  created_at: new Date('2023-01-01T00:00:00Z'),
  updated_at: new Date(),
  category: ClaimTypeEnum.Quality,
  reason: ClaimReasonEnum.PackagingDamage,
  fulfillment_event: {
    name: 'Delivery',
    to_city: 'Berlin',
    to_country: 'Germany',
    to_date: '2023-01-02T00:00:00Z',
    to_postal_code: '10115',
    to_street: 'Test Street 1',
  },
  invoice: {
    number: 'INV123456',
    payment_amount: 100,
  },
  status: ClaimStatusEnum.Created,
  invoice_id: 'INV123456',
  comment: 'Test Comment',
};

/**
 * Extracts the single item from an array or returns an empty array if the array has more than one item.
 *
 * @param {AffectedArticleType[]} array - The input array to extract the single item from
 * @return {string | []} The id of the single item from the array or an empty array
 */
export const returnArticleId = (array?: AffectedArticleType[]): string | [] => {
  if (Array.isArray(array) && array.length === 1) {
    return array[0].id;
  }
  return [];
};

/**
 * Transforms a list of products into a list of options for a dropdown or select component.
 *
 * @param {Product[]} products - The list of products to transform.
 * @return {SelectOption[]} The transformed list of product options.
 */
export const createProductOptions = (products: Product[]) => {
  return products.map((product) => ({
    id: product.id,
    value: product.id,
    label: product.product_name,
    subLabel: product.imageURL,
  }));
};
