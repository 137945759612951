import React, { useRef, useState } from 'react';
import { useScopedI18n } from '~/locales/client';
import { Icon, IconName } from '../Icon';
import twColors from 'tailwindcss/colors';
import { BannerTypeEnum } from '~/types-and-enums/bannerTypes';
import Button from '~/components/customComponents/buttons/Button';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';

const Banner = ({
  title,
  text,
  bannerType,
  showRefreshButton = false,
  hasBorder = true,
  isOverlay = false,
}: {
  title?: string;
  text: string;
  bannerType: BannerTypeEnum;
  showRefreshButton?: boolean;
  hasBorder?: boolean;
  isOverlay?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const ref = useRef(null);
  const t = useScopedI18n('banner');

  const colors = {
    [BannerTypeEnum.Success]: {
      bg: twColors.green[50],
      color: twColors.green[800],
      borderColor: twColors.green[400],
      icon: IconName.Resolved,
    },
    [BannerTypeEnum.Error]: {
      bg: twColors.pink[50],
      color: twColors.pink[800],
      borderColor: twColors.pink[400],
      icon: IconName.Alert,
    },
  };

  const borderStyle = hasBorder
    ? `border border-${colors[bannerType].borderColor}`
    : '';

  const BannerContent = () => (
    <Flex
      className={`items-center bg-${colors[bannerType].bg} rounded-md p-4 ${borderStyle}`}
    >
      <Icon
        name={colors[bannerType].icon}
        color={colors[bannerType].color}
        className="mr-4"
      />
      <div>
        {title && (
          <Text className={`font-bold text-${colors[bannerType].color}`}>
            {title}
          </Text>
        )}
        <Text className={`font-normal text-${colors[bannerType].color}`}>
          {text}
        </Text>
      </div>
      {showRefreshButton && (
        <Button buttonClassName="ml-auto" text={t('refresh')} />
      )}
    </Flex>
  );

  if (!isOverlay) {
    return <BannerContent />;
  }

  // Use custom modal for overlay
  return (
    <div
      className="fixed inset-0 bg-transparent flex justify-center items-center z-50"
      ref={ref}
      onClick={() => setIsOpen(false)}
    >
      <div
        className="bg-white rounded-lg shadow-lg max-w-md w-full"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        {isOpen && <BannerContent />}
      </div>
    </div>
  );
};

export default Banner;
